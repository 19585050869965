import React, { PureComponent } from 'react'
import styled from 'styled-components'
import SEO from '../components/SEO/SEO'
import Layout from '../components/layout'
import { Section, Container, Grid, Column } from '../components/styled/layout'
import { Title } from '../components/styled/typography'

const Info = styled.p`
  & span {
    display: block;
    color: #00000070;
  }
`

class ContactPage extends PureComponent {
  state = {}
  render() {
    return (
      <React.Fragment>
        <SEO title="Kontakta Creandia" />
        <Layout>
          <Container>
            <Section>
              <Title>
                Kontakta Creandia. <span>Låt oss diskutera nästa projekt.</span>
              </Title>
              <Grid columns="1fr 1fr">
                <Column display="flex">
                  <Grid columns="1fr 1fr">
                    <Column>
                      <Info>
                        <span>Maila oss</span>
                        <strong>info@creandia.com</strong>
                      </Info>
                    </Column>
                    <Column>
                      <Info>
                        <span>Ring oss</span>
                        <strong>073-969 36 36</strong>
                      </Info>
                    </Column>
                  </Grid>
                </Column>
              </Grid>
            </Section>
          </Container>
        </Layout>
      </React.Fragment>
    )
  }
}

export default ContactPage
